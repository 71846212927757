// index.js or App.js (depending on your structure)
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import TopBar from "./components/TopBar"; // Import the TopBar component
import "./index.css";

function MainApp() {
  const [showTopBar, setShowTopBar] = useState(true);

  useEffect(() => {
    let lastScrollTop = 0;
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setShowTopBar(false); // Hide when scrolling down
      } else {
        setShowTopBar(true); // Show when scrolling up
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {/* TopBar */}
      <div className={`top-bar ${showTopBar ? "" : "hide-top-bar"}`}>
        <TopBar />
      </div>

      {/* Navbar receives a class to adjust its position */}
      <div className={`navbar-container ${showTopBar ? "" : "navbar-top"}`}>
        <App />
      </div>
    </>
  );
}

ReactDOM.render(<MainApp />, document.getElementById("root"));
