// components/TopBar.js
import React from "react";
import "./TopBar.css";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaTiktok,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import LanguageSwitcher from "./LanguageSwitcher"; // Import the language switcher
import "../i18n"; // Import i18n configuration

function TopBar() {
  return (
    <div className="top-bar-content">
      <div className="top-bar-logo">
        <img
          className="img-fluid"
          src={`${process.env.PUBLIC_URL}/assets/logo.jpg`}
          height={64}
          alt="logo"
        />
      </div>
      <div className="top-bar-contact">
        <span>📞 +971-555-55555</span>
        <div className="top-bar-socials">
          <a href="#" target="_blank" rel="noreferrer">
            <FaFacebook />
          </a>
          <a href="#" target="_blank" rel="noreferrer">
            <FaTwitter />
          </a>

          <a href="#" target="_blank" rel="noreferrer">
            <FaInstagram />
          </a>
        </div>
      </div>
      <LanguageSwitcher />
    </div>
  );
}

export default TopBar;
