import React from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang); // Change language
  };

  return (
    <div style={{ display: "flex" }}>
      <button className="btn" onClick={() => changeLanguage("en")}>
        English
      </button>
      <button className="btn" onClick={() => changeLanguage("ar")}>
        العربية
      </button>
    </div>
  );
}

export default LanguageSwitcher;
