import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "./Contact.css";

function Contact() {
  const { t } = useTranslation(); // Translation hook

  return (
    <div className="contact-container">
      <div className="contact-column">
        {/* First Column: Contact Info and Form */}
        <div className="contact-info">
          {/* Row 1: Mobile, WhatsApp, and Address */}
          <div className="info-row">
            <div className="info-item">
              <h3>{t("contact.mobile_number")}</h3>
              <p>+1-800-SEA-SHIP</p> {/* Static number */}
            </div>
            <div className="info-item">
              <h3>{t("contact.whatsapp")}</h3>
              <p>+1-800-WHATSAPP</p> {/* Static number */}
            </div>
            <div className="info-item">
              <h3>{t("contact.address")}</h3>
              <p>{t("contact.address_value")}</p>
            </div>
          </div>
          {/* Row 2: Contact Form */}
          <div className="contact-form">
            <h3>{t("contact.contact_us")}</h3>
            <form>
              <label>{t("contact.name")}</label>
              <input type="text" name="name" required />

              <label>{t("contact.email")}</label>
              <input type="email" name="email" required />

              <label>{t("contact.message")}</label>
              <textarea name="message" rows="5" required></textarea>

              <button type="submit">{t("contact.submit")}</button>
            </form>
          </div>
        </div>
      </div>

      {/* Second Column: Map */}
      <div className="map-column">
        <h3>{t("contact.our_location")}</h3>
        <div className="map-container">
          <iframe
            title="Our Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509301!2d144.95373531531755!3d-37.81720997975154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43f4b0c0db%3A0x2e0e3b2c3fef37f9!2sSea%20Shipping%20Co.!5e0!3m2!1sen!2sus!4v1618467075206!5m2!1sen!2sus"
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
