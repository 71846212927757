import React from "react";
import { useTranslation } from "react-i18next"; // Import the useTranslation hook
import "./Services.css";
import Numbers from "../components/Numbers";

function Services() {
  const { t } = useTranslation(); // Translation function from react-i18next

  // Fetch services from the translation file
  const services = t("servicesList", { returnObjects: true }); // Using 'returnObjects' to fetch the array of services

  return (
    <div className="services-container">
      <h2>{t("services_title")}</h2> {/* Localized title */}
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-image">
              <img
                src={`./assets/services/${service.image}`} // Image path
                alt={service.title}
              />
            </div>
            <div className="service-title">
              <h3>{service.title}</h3> {/* Localized title */}
            </div>
            <div className="service-content">
              <p>{service.description}</p> {/* Localized description */}
              <button className="more-btn">{t("learn_more")}</button>{" "}
              {/* Localized "More" button */}
            </div>
          </div>
        ))}
      </div>
      <Numbers />
    </div>
  );
}

export default Services;
