import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaUsers, FaShip, FaCity, FaRoad } from "react-icons/fa"; // Import icons
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import "./Numbers.css";

function Numbers() {
  const { t } = useTranslation(); // Translation function

  // Target numbers
  const [clients, setClients] = useState(0);
  const [shipments, setShipments] = useState(0);
  const [cities, setCities] = useState(0);
  const [miles, setMiles] = useState(0);

  // Use Effect to animate the numbers
  useEffect(() => {
    const timer = setTimeout(() => {
      setClients(4000);
      setShipments(16000);
      setCities(420);
      setMiles(4400);
    }, 500); // Delay to simulate loading

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="numbers-container">
      <h2>{t("numbers_title")}</h2> {/* Translated title */}
      <div className="numbers-grid">
        {/* Clients */}
        <motion.div
          className="number-card"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <FaUsers className="icon" />
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.5 }}
          >
            {clients.toLocaleString()}
          </motion.h3>
          <p>{t("clients_label")}</p> {/* Translated label */}
        </motion.div>

        {/* Shipments */}
        <motion.div
          className="number-card"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <FaShip className="icon" />
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.6 }}
          >
            {shipments.toLocaleString()}
          </motion.h3>
          <p>{t("shipments_label")}</p> {/* Translated label */}
        </motion.div>

        {/* Cities */}
        <motion.div
          className="number-card"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <FaCity className="icon" />
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.7 }}
          >
            {cities.toLocaleString()}
          </motion.h3>
          <p>{t("cities_label")}</p> {/* Translated label */}
        </motion.div>

        {/* Thousand Miles p/y */}
        <motion.div
          className="number-card"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <FaRoad className="icon" />
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.8 }}
          >
            {miles.toLocaleString()}
          </motion.h3>
          <p>{t("miles_label")}</p> {/* Translated label */}
        </motion.div>
      </div>
    </div>
  );
}

export default Numbers;
