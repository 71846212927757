// components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { useTranslation } from "react-i18next";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation(); // t is the translation function

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      {/* Mobile Logo */}

      {/* Hamburger Menu Icon */}
      <div
        className={`menu-icon ${isOpen ? "open" : ""}`}
        onClick={toggleSidebar}
      >
        <div className="menu-line"></div>
        <div className="menu-line"></div>
        <div className="menu-line"></div>
      </div>

      {/* Desktop Navbar Links */}
      <ul className="navbar-links">
        <li>
          <Link to="/">{t("home")}</Link>
        </li>
        <li>
          <Link to="/about">{t("about_us")}</Link>
        </li>
        <li>
          <Link to="/services">{t("services")}</Link>
        </li>
        <li>
          <Link to="/contact">{t("contact_us")}</Link>
        </li>
        <li>
          <Link to="/enquiry">{t("enquiry")}</Link>
        </li>
        <li>
          <Link to="/tracking">{t("tracking")}</Link>
        </li>
      </ul>

      {/* Mobile Sidebar with Contact and Socials */}
      <div className={`sidebar ${isOpen ? "sidebar-open" : ""}`}>
        <div className="sidebar-header">
          <span>📞 +1-800-SEA-SHIP</span>
          <div className="sidebar-socials">
            <a href="#" target="_blank" rel="noreferrer">
              Facebook
            </a>
            <a href="#" target="_blank" rel="noreferrer">
              Twitter
            </a>
            <a href="#" target="_blank" rel="noreferrer">
              Instagram
            </a>
          </div>
        </div>
        <ul>
          <li>
            <Link to="/" onClick={toggleSidebar}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={toggleSidebar}>
              About Us
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={toggleSidebar}>
              Services
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={toggleSidebar}>
              Contact Us
            </Link>
          </li>
          <li>
            <Link to="/enquiry" onClick={toggleSidebar}>
              Enquiry
            </Link>
          </li>
          <li>
            <Link to="/tracking" onClick={toggleSidebar}>
              Tracking
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
