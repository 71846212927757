import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaTiktok,
  FaWhatsapp,
  FaYoutube,
  FaApple,
  FaGooglePlay,
} from "react-icons/fa";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcPaypal,
  FaCcApplePay,
  FaCcAmex,
} from "react-icons/fa"; // Payment Icons
import { useTranslation } from "react-i18next"; // Import useTranslation
import "./BottomBar.css";

function BottomBar() {
  const { t } = useTranslation(); // Translation hook

  return (
    <div className="bottom-bar">
      <div className="row">
        {/* First Column: About Us and Social Media */}
        <div className="aboutus">
          <div className="about1">
            <h4 className="heading">{t("bottom_bar.about_us")}</h4>
            <p>{t("bottom_bar.about_us_text")}</p>
          </div>

          <div className="social-media">
            <h4 className="heading">{t("bottom_bar.follow_us")}</h4>
            <ul className="list-unstyled d-flex m-0">
              <li>
                <a
                  href="https://www.instagram.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook />
                </a>
              </li>
              <li>
                <a
                  href="https://www.twitter.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTwitter />
                </a>
              </li>
              <li>
                <a
                  href="https://www.whatsapp.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsapp />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTiktok />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube />
                </a>
              </li>
            </ul>
          </div>

          <div className="payment">
            <h4 className="heading">{t("bottom_bar.we_accept")}</h4>
            <ul className="list-unstyled d-flex align-items-center">
              <li>
                <FaCcVisa />
              </li>
              <li>
                <FaCcMastercard />
              </li>
              <li>
                <FaCcApplePay />
              </li>
              <li>
                <FaCcPaypal />
              </li>
              <li>
                <FaCcAmex />
              </li>
            </ul>
          </div>
        </div>

        {/* Second Column: Quick Links */}
        <div className="aboutus">
          <div className="links">
            <h4 className="heading">{t("bottom_bar.quick_links")}</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/about-us">{t("quick_links.about_us")}</a>
              </li>
              <li>
                <a href="/services">{t("quick_links.services")}</a>
              </li>
              <li>
                <a href="/online-enquiry">{t("quick_links.online_enquiry")}</a>
              </li>
              <li>
                <a href="/contacts">{t("quick_links.contacts")}</a>
              </li>
              <li>
                <a href="/careers">{t("quick_links.careers")}</a>
              </li>
              <li>
                <a href="/terms-conditions">
                  {t("quick_links.terms_conditions")}
                </a>
              </li>
              <li>
                <a href="/payment-policy">{t("quick_links.payment_policy")}</a>
              </li>
              <li>
                <a href="/cookies-policy">{t("quick_links.cookies_policy")}</a>
              </li>
              <li>
                <a href="/privacy-policy">{t("quick_links.privacy_policy")}</a>
              </li>
              <li>
                <a href="/delivery-policy">
                  {t("quick_links.delivery_policy")}
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Third Column: Recent Posts & App Download */}
        <div className="aboutus">
          <div className="posts">
            <h4 className="heading">{t("bottom_bar.recent_posts")}</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/post-1">
                  <div className="image">
                    <img
                      src="https://example.com/recent-post1.png"
                      alt="Recent Post 1"
                    />
                  </div>
                  <div className="details">
                    <h4 className="title">{t("posts.post_1_title")}</h4>
                    <span className="date">{t("posts.post_1_date")}</span>
                  </div>
                </a>
              </li>
              <li>
                <a href="/post-2">
                  <div className="image">
                    <img
                      src="https://example.com/recent-post2.png"
                      alt="Recent Post 2"
                    />
                  </div>
                  <div className="details">
                    <h4 className="title">{t("posts.post_2_title")}</h4>
                    <span className="date">{t("posts.post_2_date")}</span>
                  </div>
                </a>
              </li>
            </ul>
            <a href="/blog" className="all">
              {t("bottom_bar.see_all")}
            </a>
          </div>

          <div className="download">
            <h4 className="heading">{t("bottom_bar.download_our_app")}</h4>
            <ul className="list-unstyled d-flex">
              <li>
                <a
                  href="https://apps.apple.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaApple />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGooglePlay />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomBar;
