// components/Footer.js
import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <h7>© 2024 TASSILI SEA CARGO LLC. All rights reserved.</h7>
    </footer>
  );
}

export default Footer;
