import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "./FloatingWhatsAppButton.css";

function FloatingWhatsAppButton() {
  return (
    <a
      href="https://wa.me/1234567890" // Replace with your WhatsApp number in international format
      className="floating-whatsapp"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp />
    </a>
  );
}

export default FloatingWhatsAppButton;
