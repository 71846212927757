import React, { useState } from "react";
import { useTranslation } from "react-i18next"; // Import translation hook
import {
  FaShippingFast,
  FaCalendarAlt,
  FaMapMarkerAlt,
  FaCheckCircle,
} from "react-icons/fa";
import "./Enquiry.css";

function Enquiry() {
  const { t } = useTranslation(); // Use the translation hook to access translations
  const [step, setStep] = useState(1); // Track the current step
  const [formData, setFormData] = useState({
    service: "",
    from: "",
    to: "",
    date: "",
    fullName: "",
    email: "",
    phone: "",
    shipmentDetails: "",
    shipmentComments: "",
  });

  // Get the services list dynamically from the translation JSON
  const servicesList = t("servicesList", { returnObjects: true });

  // Function to handle form inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle next and previous step navigation
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);
  const goToStep = (stepNumber) => {
    if (stepNumber <= step) setStep(stepNumber);
  };

  // Tabs Icons and Labels
  const tabs = [
    { icon: <FaShippingFast />, label: t("services_title") },
    { icon: <FaMapMarkerAlt />, label: "From" },
    { icon: <FaMapMarkerAlt />, label: "To" },
    { icon: <FaCalendarAlt />, label: "Date" },
    { icon: <FaCheckCircle />, label: "Summary" },
  ];

  // Render the form fields based on the current step
  const renderStepFields = () => {
    switch (step) {
      // Step 1: Services list with cards
      case 1:
        return (
          <>
            <h3>{t("services_title")}</h3>
            <ul className="services-list">
              {servicesList.map((service, index) => (
                <li key={index}>
                  <input
                    type="radio"
                    id={`service-${index}`}
                    name="service"
                    value={service.title}
                    checked={formData.service === service.title}
                    onChange={handleChange}
                  />
                  <label htmlFor={`service-${index}`}>
                    <span>{service.title}</span>
                  </label>
                </li>
              ))}
            </ul>
          </>
        );

      case 2:
        return (
          <>
            <h3>From</h3>
            <input
              type="text"
              name="from"
              value={formData.from}
              onChange={handleChange}
              placeholder="Enter the origin"
            />
          </>
        );
      case 3:
        return (
          <>
            <h3>To</h3>
            <input
              type="text"
              name="to"
              value={formData.to}
              onChange={handleChange}
              placeholder="Enter the destination"
            />
          </>
        );
      case 4:
        return (
          <>
            <h3>Choose Date</h3>
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </>
        );
      case 5:
        return (
          <>
            <h3>Shipping Details</h3>
            <ul>
              <li>
                <strong>Service:</strong> {formData.service}
              </li>
              <li>
                <strong>From:</strong> {formData.from}
              </li>
              <li>
                <strong>To:</strong> {formData.to}
              </li>
              <li>
                <strong>Date:</strong> {formData.date}
              </li>
            </ul>
            <h3>User Information</h3>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              placeholder="Full Name"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone Number"
              required
            />
            <h3>Additional Shipment Details</h3>
            <textarea
              name="shipmentDetails"
              value={formData.shipmentDetails}
              onChange={handleChange}
              placeholder="Enter shipment details"
              rows="3"
            />
            <textarea
              name="shipmentComments"
              value={formData.shipmentComments}
              onChange={handleChange}
              placeholder="Any additional comments?"
              rows="3"
            />
          </>
        );
      default:
        return null;
    }
  };

  // Validate form fields before allowing to proceed to the next step
  const isNextDisabled = () => {
    switch (step) {
      case 1:
        return !formData.service;
      case 2:
        return !formData.from;
      case 3:
        return !formData.to;
      case 4:
        return !formData.date;
      case 5:
        return !formData.fullName || !formData.email || !formData.phone;
      default:
        return false;
    }
  };

  return (
    <div className="enquiry-container">
      <h2>{t("enquiry")}</h2>

      {/* Tabs */}
      <div className="tabs">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${step >= index + 1 ? "active" : ""}`}
            onClick={() => goToStep(index + 1)}
          >
            <div className="tab-icon">{tab.icon}</div>
            <span>{tab.label}</span>
            {step > index + 1 && <FaCheckCircle className="done-icon" />}
          </div>
        ))}
      </div>

      <form>
        {renderStepFields()}

        {/* Navigation buttons */}
        <div className="form-navigation">
          {step > 1 && (
            <button type="button" onClick={prevStep}>
              Back
            </button>
          )}
          {step < 5 && (
            <button
              type="button"
              onClick={nextStep}
              disabled={isNextDisabled()}
            >
              Next
            </button>
          )}
          {step === 5 && <button type="submit">Submit</button>}
        </div>
      </form>
    </div>
  );
}

export default Enquiry;
