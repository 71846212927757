// pages/About.js
import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>
        We are a leading sea freight company, providing world-class shipping
        solutions across the globe. With decades of experience, we ensure timely
        and secure shipping for all your needs.
      </p>
    </div>
  );
}

export default About;
