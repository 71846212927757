// pages/Tracking.js
import React from "react";
import "./Tracking.css";

function Tracking() {
  return (
    <div className="tracking">
      <h1>Track Your Shipment</h1>
      <form>
        <label>Tracking Number:</label>
        <input type="text" name="trackingNumber" required />
        <button type="submit">Track</button>
      </form>
    </div>
  );
}

export default Tracking;
