// pages/Home.js
import React from "react";
import "./Home.css";

function Home() {
  // Create an array of 100 items
  const repeatedParagraphs = Array.from({ length: 100 }, (_, i) => (
    <p key={i}>Your trusted partner in sea freight and logistics solutions.</p>
  ));

  return (
    <div className="home">
      <header className="hero">
        <h1>Welcome to Sea Shipping Co.</h1>
        {repeatedParagraphs}
        <button className="cta-btn">Explore Our Services</button>
      </header>
    </div>
  );
}

export default Home;
